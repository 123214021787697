// 运输类型
export const TRANSPORT_TYPE = {
  NORMAL_TIME: 0, // 普通时效
  QUICK_SHIP_TIME: 1, // QS时效
  LARGE_TIME: 2, // 大件时效（海运、陆运）
  SHOP_TIME: 3,  // 店铺时效
}

// 新运输时间类型
export const NEW_TRANSPORT_TIME_TYPE = {
  DAY_RANGE: 1, // 天（天数范围7-8）
  DATE_RANGE: 2, // 日期范围（1732710479-1733056079）
}

// 新跨境运输时间类型
export const NEW_OUT_TRANSPORT_TIME_TYPE = {
  DAY_RANGE: 0, // 天（天数范围7-8）
  DATE_RANGE: 1, // 日期范围（1732710479-1733056079）
}

// 旧运输时间类型
export const OLD_TRANSPORT_TIME_TYPE = {
  DAY_RANGE: 0, // 天（天数范围7-8）
  DATE_RANGE: 1, // 日期范围（1732710479-1733056079）
}

// 日期类型
export const DATE_TYPE = {
  NATURAL_DAY: 0, // 自然日
  WORKING_DAY: 1, // 工作日
}

// 大件时效详细类型
export const LARGE_DETAIL_TYPE = {
  NOT_LARGE: 0, // 否
  SEA_TRANSPORT: 1, // 海运
  LAND_TRANSPORT: 2, // 陆运
}

// QS标识类型（注=1此商品是QS；如果=2此商品同时含有QS和普通商品，需要同时展示QS和普通时效）
export const QUICK_SHIP_TYPE = {
  NOT_QUICK_SHIP: 0, // 普通商品
  ALL_QUICK_SHIP: 1, // qs商品
  PART_QUICK_SHIP: 2, // 部分QS部分普通商品
}
