import { timeTransformer, template } from '@shein/common-function'
import orderLogic from 'public/src/pages/common/orderLogic'
import { NEW_TRANSPORT_TIME_TYPE, OLD_TRANSPORT_TIME_TYPE, DATE_TYPE } from './constant'
import { ORDER_DATE, SIMPLE_ORDER_DATE } from 'public/src/pages/common/biz_helper/timer_format/dictionary'

export const handlerDate = (date, localData) => {
  return orderLogic.orderDateFormat(date, false, localData)
}

// 格式化时间
export const formatTime = (time, localData = null) => {
  let timeString = ''
  if (time) {
    if (/^\d{10,13}$/.test(time)) {
      timeString = handlerDate(time, localData)
    } else if (/^\d{10,13}-\d{10,13}$/.test(time)) {
      const transportTime = time.split('-')
      const startTime = handlerDate(transportTime[0], localData)
      const endTime = handlerDate(transportTime[1], localData)
      timeString = `${startTime} ~ ${endTime}`
    } else {
      timeString = time
    }
  }
  return timeString
}

// 截取N日达的配置内容
export const splitTransportTime = (time = '', localData = {}) => {
  const handle = time.split('-')
  const str = handle?.length > 1 ? handle[1] : handle[0]
  return formatTime(str, localData)
}

// 格式化双时效文案
export const formatDoubleTime = (params = {}) => {
  let {
    originShippingDesc,
    originDeliveryDesc,
    transportTimeType,
    transportTime,
    subTransportTime,
    useNewEnum = false,
    localData = null
  } = params
  if (!originShippingDesc || !originDeliveryDesc || !subTransportTime) {
    return ''
  }
  // 根据传入useNewEnum值判断使用旧枚举还是新枚举（普通时效使用旧枚举，其他时效使用新枚举）
  const DAY_RANGE = useNewEnum
    ? NEW_TRANSPORT_TIME_TYPE.DAY_RANGE
    : OLD_TRANSPORT_TIME_TYPE.DAY_RANGE
  if (transportTimeType == DAY_RANGE) {
    const shippingDesc = originShippingDesc.replace('%s', transportTime)
    const deliveryDesc = originDeliveryDesc.replace('%s', formatTime(subTransportTime, localData))

    return `${shippingDesc} (${deliveryDesc})`
  } else {
    const shippingDesc = originShippingDesc.replace('%s', formatTime(subTransportTime, localData))
    const deliveryDesc = originDeliveryDesc.replace('%s', formatTime(transportTime, localData))
    return `${deliveryDesc} (${shippingDesc})`
  }
}

// 格式化双时效文案
export const formatDoubleTimeNew = (params = {}) => {
  let {
    transportTimeType,
    subTransportTime,
    useNewEnum = false,
    dataType,
    language = {},
    localData = null
  } = params
  if (!subTransportTime) {
    return ''
  }
  let text = ''
  // 根据传入useNewEnum值判断使用旧枚举还是新枚举（普通时效使用旧枚举，其他时效使用新枚举）
  const DAY_RANGE = useNewEnum
    ? NEW_TRANSPORT_TIME_TYPE.DAY_RANGE
    : OLD_TRANSPORT_TIME_TYPE.DAY_RANGE
  if (transportTimeType == DAY_RANGE) {  
    text = formatTime(subTransportTime, localData)
    return text
  } else {
    if (dataType == DATE_TYPE.NATURAL_DAY) {
      text = template(formatTime(subTransportTime, localData), language.SHEIN_KEY_PWA_28881)
    } else if (dataType == DATE_TYPE.WORKING_DAY) {
      text = template(formatTime(subTransportTime, localData), language.SHEIN_KEY_PWA_24764)
    }
  }

  return text
}

// 获取时效新字段
export const getNewTransportField = (mallInfo, type) => {
  let handle = mallInfo?.shipped_transport_time_list
  if (!handle?.length) {
    handle = mallInfo?.transport_time_list
  }
  return handle?.find(item => item.type == type) || {}
}

export const getTimestamp = (date = 0) => {
  date = (typeof date == 'string') ? date.replaceAll('-', '/') : date
  return Number(date) ? (`${date}`.length > 10 ? date : date * 1000) : new Date(date).getTime()
}

// 简单时间转换
const transformTime = (params = {}) => {
  let { date, isSimple = false, localData = null } = params
  const dateMap = isSimple ? SIMPLE_ORDER_DATE : ORDER_DATE
  date = getTimestamp(date)

  return timeTransformer({
    time: date,
    sDateMap: dateMap
  }, localData)
}

/**
 * 时间范围转换
 * 1、跨年：Dec 31 2023-Jan 02 2024
 * 2、跨月不跨年：May 28-Jun 5
 * 3、不跨月不跨年：Jun 6-24
 * @param {object} param
 * @param {string | number} param.startTime 开始时间
 * @param {string | number} param.endTime 结束时间
 * @param {object} param.localData responseLocals
 * @returns {object} response 返回结果
 * @returns {string} response.startTime 转换后的开始时间
 * @returns {string} response.endTime 转换后的结束时间
 * @returns {string} response.precision 转换后的对应的精度 1-year 2-month 3-day
 */
export const formatDateRange = (params = {}) => {
  const { startTime, endTime, localData = {} } = params
  const startTimestamp = getTimestamp(startTime)
  const endTimestamp = getTimestamp(endTime)

  const startDate = new Date(startTimestamp)
  const endDate = new Date(endTimestamp)
  const startYear = startDate.getFullYear() // 起始时间的年份
  const endYear = endDate.getFullYear()    // 结束时间的年份
  const startMonth = startDate.getMonth() // 起始时间的月份
  const endMonth = endDate.getMonth()    // 结束时间的月份

  // 根据年份/月份的情况，生成不同的格式
  if (startYear !== endYear) {
    // 跨年：附加年份
    const startTimeStr = transformTime({ date: startTimestamp, localData })
    const endTimeStr = transformTime({ date: endTimestamp, localData })
    return { startTime: startTimeStr, endTime: endTimeStr, precision: 'year' }
  } else if (startMonth !== endMonth) {
    // 跨月但不跨年：省略年份
    const startTimeStr = transformTime({ date: startTimestamp, localData, isSimple: true })
    const endTimeStr = transformTime({ date: endTimestamp, localData, isSimple: true })
    return { startTime: startTimeStr, endTime: endTimeStr, precision: 'month' }
  } else {
    // 同月同年
    let startTimeStr = transformTime({ date: startTimestamp, localData, isSimple: true })
    let endTimeStr = transformTime({ date: endTimestamp, localData, isSimple: true })
    let precision = 'month' 

    if (startTimeStr.includes('/')) { 
      // 如果是'MM/DD'格式
      const endDay = endTimeStr.split('/').pop() || ''
      if (endDay) {
        precision = 'day'
        endTimeStr = endDay
      }
      return { startTime: startTimeStr, endTime: endTimeStr, precision }
    } else {
      // 如果是'MMM DD'、'DD MMM'格式
      const isRight = /\d/g.test(startTimeStr.slice(0, 1))  // 月份在右边
      if (isRight) {
        const startDay = startTimeStr.split(' ').shift() || ''
        if (startDay) {
          precision = 'day'
          startTimeStr = startDay
        }
        return { startTime: startTimeStr, endTime: endTimeStr, precision }
      } else {
        const endDay = endTimeStr.split(' ').pop() || ''
        if (endDay) {
          precision = 'day'
          endTimeStr = endDay
        }
        return { startTime: startTimeStr, endTime: endTimeStr, precision }
      }
    }
  }
}


