import { template } from '@shein/common-function'
import { TRANSPORT_TYPE, DATE_TYPE, NEW_TRANSPORT_TIME_TYPE, LARGE_DETAIL_TYPE } from './constant'
import { formatTime, formatDoubleTime, formatDoubleTimeNew } from './utils'

// 获取海陆运标题
export const getSeaOrLandTypeText = (goodsList = [], language = {}) => {
  if (goodsList.some(item => item.large_sea_and_land == LARGE_DETAIL_TYPE.SEA_TRANSPORT)){
    return language.SHEIN_KEY_PWA_32176 || ''
  }
  if (goodsList.some(item => item.large_sea_and_land == LARGE_DETAIL_TYPE.LAND_TRANSPORT)){
    return language.SHEIN_KEY_PWA_32175 || ''
  }
  return ''
}

// 获取大件时效信息
export const formatLargeTimeDesc = (params = {}) => {
  const {
    language = {}, // 多语言对象
    isUnPaidOrder = false, // 是否未支付（true-未支付 false-已支付）
    showDoubleShippingTime = false, // 是否开启双时效abt
    localData = {}, // responseLocals
    timeList = [],  // 时效列表
    goodsList = [], //商品列表
  } = params
  const timeInfo = timeList.find(item => item.type == TRANSPORT_TYPE.LARGE_TIME) || {}

  const { 
    transport_time: transportTime,
    origin_delivery_desc: originDeliveryDesc = '',
    origin_shipping_desc: originShippingDesc = '',
    sub_transport_time: subTransportTime = '',
    transport_time_type: timeType,
    transport_date_type: dataType,
    // supple_desc: suppleDesc = '',
    title = '',
  } = timeInfo
  if (!transportTime) return ''
  const seaOrLandTypeText = getSeaOrLandTypeText(goodsList, language)
  let titleText = title ? title : seaOrLandTypeText

  let text = ''
  //满足双时效展示条件
  if (
    showDoubleShippingTime &&
    !isUnPaidOrder &&
    originDeliveryDesc &&
    originShippingDesc &&
    subTransportTime
  ) {
    text = formatDoubleTime({
      originDeliveryDesc,
      originShippingDesc,
      subTransportTime,
      transportTime,
      transportTimeType: timeType,
      useNewEnum: true,
      localData
    })
  } else if (timeType == NEW_TRANSPORT_TIME_TYPE.DATE_RANGE) {
    const formatDate = formatTime(transportTime, localData)
    if (isUnPaidOrder || !originDeliveryDesc) {
      if (isUnPaidOrder){
        text = `${seaOrLandTypeText}: ${template(formatDate, language.SHEIN_KEY_PWA_26651)}`
      } else {
        text = `${titleText}: ${template(formatDate, language.SHEIN_KEY_PWA_26651)}`
      }
    } else if (originDeliveryDesc) {
      text = originDeliveryDesc.replace('%s', formatDate)
    }
  } else if (timeType == NEW_TRANSPORT_TIME_TYPE.DAY_RANGE) {
    const isShow = !originShippingDesc || isUnPaidOrder
    if (dataType == DATE_TYPE.NATURAL_DAY) {
      if (isShow){
        if (isUnPaidOrder) {
          text = `${seaOrLandTypeText}: ${template(transportTime, language.SHEIN_KEY_PWA_28881)}`
        } else{
          text = `${titleText}: ${template(transportTime, language.SHEIN_KEY_PWA_28881)}`
        } 
      } else if (originShippingDesc) {
        text = originShippingDesc.replace('%s', transportTime)
      }
    } else if(dataType == DATE_TYPE.WORKING_DAY) {
      if (isShow){
        if (isUnPaidOrder) {
          text = `${seaOrLandTypeText}: ${template(transportTime, language.SHEIN_KEY_PWA_24764)}`
        } else {
          text = `${titleText}: ${template(transportTime, language.SHEIN_KEY_PWA_24764)}`
        }     
      } else if(originShippingDesc) {
        text = originShippingDesc.replace('%s', transportTime)
      }
    }
  }    
  return text
}

// 获取大件时效信息
export const formatLargeTimeDescNew = (params = {}) => {
  const {
    language = {}, // 多语言对象
    showDoubleShippingTime = false, // 是否开启双时效abt
    localData = {}, // responseLocals
    timeList = [],  // 时效列表
  } = params
  const timeInfo = timeList.find(item => item.type == TRANSPORT_TYPE.LARGE_TIME) || {}

  const { 
    transport_time: transportTime,
    sub_transport_time: subTransportTime = '',
    transport_time_type: timeType,
    transport_date_type: dataType,
  } = timeInfo
  if (!transportTime) return ''
  let text, doubleTransportText = ''
  //满足双时效展示条件
  if (
    showDoubleShippingTime &&
    subTransportTime
  ) {
    doubleTransportText = formatDoubleTimeNew({
      subTransportTime,
      transportTime,
      language,
      dataType,
      transportTimeType: timeType,
      useNewEnum: true,
      localData
    })
  } 
  
  if (timeType == NEW_TRANSPORT_TIME_TYPE.DATE_RANGE) {
    const formatDate = formatTime(transportTime, localData)
    text = formatDate
  } else if (timeType == NEW_TRANSPORT_TIME_TYPE.DAY_RANGE) {
    if (dataType == DATE_TYPE.NATURAL_DAY) {
      text =  template(transportTime, language.SHEIN_KEY_PWA_28881)
    } else if(dataType == DATE_TYPE.WORKING_DAY) {
      text = template(transportTime, language.SHEIN_KEY_PWA_24764)
    } else {
      text = template(transportTime, language.SHEIN_KEY_PWA_24764)
    }
  }

  return {
    doubleTransportText,
    text
  }
}
