import { template } from '@shein/common-function'
import { TRANSPORT_TYPE, DATE_TYPE, OLD_TRANSPORT_TIME_TYPE } from './constant'
import { formatTime, formatDoubleTime, splitTransportTime, formatDoubleTimeNew } from './utils'

// 获取普通时效信息
export const formatNormalTimeDesc = (params = {}) => {
  const {
    language = {}, // 多语言对象
    isUnPaidOrder = false, // 是否未支付（true-未支付 false-已支付）
    showDoubleShippingTime = false, // 是否开启双时效abt
    timeList = [], // 时效列表
    localData = {}, // responseLocals
  } = params
  const timeInfo = timeList.find(item => item.type == TRANSPORT_TYPE.NORMAL_TIME) || {}
    
  const { 
    transport_time: transportTime,
    origin_delivery_desc: originDeliveryDesc = '',
    origin_shipping_desc: originShippingDesc = '',
    sub_transport_time: subTransportTime = '',
    transport_time_type: timeType,
    transport_date_type: dataType,
    supple_desc: suppleDesc = '',
  } = timeInfo
  if (!transportTime) return ''
  
  let text = ''
  //满足双时效展示条件
  if (
    showDoubleShippingTime &&
    !isUnPaidOrder &&
    originDeliveryDesc &&
    originShippingDesc &&
    subTransportTime
  ) {
    text = formatDoubleTime({
      originDeliveryDesc,
      originShippingDesc,
      subTransportTime,
      transportTime,
      transportTimeType: timeType,
      localData
    })
  } else if (timeType == OLD_TRANSPORT_TIME_TYPE.DATE_RANGE) {
    const formatDate = formatTime(transportTime, localData)
    if (isUnPaidOrder) {
      text = template(formatDate, language.SHEIN_KEY_PWA_26651)
    } else {
      const originDesc = `${language.SHEIN_KEY_PWA_15627} ${formatDate}`
      text = originDeliveryDesc ? originDeliveryDesc.replace('%s', formatDate) : originDesc
    }
  } else if (timeType == OLD_TRANSPORT_TIME_TYPE.DAY_RANGE) {
    const isShow = (!originShippingDesc && !originDeliveryDesc) || isUnPaidOrder
    if (isShow && dataType == DATE_TYPE.NATURAL_DAY) {
      text = template(transportTime, language.SHEIN_KEY_PWA_28881)
    } else if (isUnPaidOrder && dataType == DATE_TYPE.WORKING_DAY) {
      text = template(transportTime, language.SHEIN_KEY_PWA_24764)
    } else if (originShippingDesc) {
      text = originShippingDesc.replace('%s', transportTime)
    } else {
      text = template(transportTime, language.SHEIN_KEY_PWA_24764)
    }
  }
  if (suppleDesc) {
    text = `${text} ${suppleDesc}`
  }

  return text
}

// 获取普通时效文案
export const getCommonDurationText = (params = {}) =>{
  const { 
    language = {},
    abtInfo = {},
    mallInfo = {},
    localData = {}
  } = params
  if (abtInfo.common_N == 'on') {
    const transportTimeList = mallInfo?.transport_time_list || []
    const timeInfo = transportTimeList.find(item => item.type == TRANSPORT_TYPE.NORMAL_TIME) || {}
 
    if (timeInfo.days_delivery_flag == 1) {
      let orderQuickshipWord = ''
      if (timeInfo?.transport_time_type == OLD_TRANSPORT_TIME_TYPE.DAY_RANGE) {
        orderQuickshipWord = splitTransportTime(timeInfo.transport_time, localData) 
      } else if (timeInfo?.transport_time_type == OLD_TRANSPORT_TIME_TYPE.DATE_RANGE) {
        orderQuickshipWord = splitTransportTime(timeInfo.sub_transport_time, localData)
      }
      if (orderQuickshipWord) {
        return template(orderQuickshipWord, language.SHEIN_KEY_PWA_34685)
      }
    }
  }
  return ''
}

export const formatNormalTimeDescNew = (params = {}) => {
  const {
    language = {}, // 多语言对象
    showDoubleShippingTime = false, // 是否开启双时效abt
    timeList = [], // 时效列表
    localData = {}, // responseLocals
  }  = params
  const timeInfo = timeList.find(item => item.type == TRANSPORT_TYPE.NORMAL_TIME) || {}
  const { 
    transport_time: transportTime,
    sub_transport_time: subTransportTime = '',
    transport_time_type: timeType,
    transport_date_type: dataType,
  } = timeInfo
  if (!transportTime) return ''

  let text = ''
  let doubleTransportText = ''
  //满足双时效展示条件
  if (
    showDoubleShippingTime &&
    subTransportTime
  ) {
    doubleTransportText = formatDoubleTimeNew({
      subTransportTime,
      transportTime,
      language,
      dataType,
      transportTimeType: timeType,
      localData
    })
  } 
  if (timeType == OLD_TRANSPORT_TIME_TYPE.DATE_RANGE) {
    const formatDate = formatTime(transportTime, localData)
    text = formatDate
  } else if (timeType == OLD_TRANSPORT_TIME_TYPE.DAY_RANGE) {
    if (dataType == DATE_TYPE.NATURAL_DAY) {
      text = template(transportTime, language.SHEIN_KEY_PWA_28881)
    } else if (dataType == DATE_TYPE.WORKING_DAY) {
      text = template(transportTime, language.SHEIN_KEY_PWA_24764)
    }
  }
  return {
    text,
    doubleTransportText
  }
}

